import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "searchbar" })
class SearchBarModule extends VuexModule {
  private currentSearch = "";
  private visible = false;

  get search(): string {
    return this.currentSearch;
  }

  get getVisible(): boolean {
    return this.visible;
  }

  @Mutation
  public mutateSearch(currentSearch: string): void {
    this.currentSearch = currentSearch;
  }

  @Action
  public searchTable(currentSearch: string): void {
    this.context.commit("mutateSearch", currentSearch);
  }

  @Mutation
  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
  @Action
  public setVisibleAction(visible: boolean): void {
    this.context.commit("setVisible", visible);
  }
}

export default SearchBarModule;
