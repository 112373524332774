import { VuelandoTheme } from "@zelando/vuelando/dist/types/helpers/theme";

export const defaultLightTheme: VuelandoTheme = {
  text: {
    primary: {
      default: "#4954BA",
    },
    neutralAlternate: {
      default: "#FFFFFF",
      subdued: "#ACABAA",
      placeholder: "#C7C6C6",
      disabled: "#777675",
    },
    neutral: {
      default: "#161E26",
      subdued: "#5F5E5D",
      placeholder: "#91918F",
      disabled: "#777675",
    },
    success: {
      default: "#008051",
    },
    warning: {
      default: "#A14D00",
    },
    error: {
      default: "#C62B46",
    },
    onAction: {
      default: "#FFFFFF",
      alternate: "#000000",
    },
    onInteractive: {
      default: "#FFFFFF",
      disabled: "#000000",
    },
    onSurface: {
      default: "#000000",
      disabled: "#777675",
    },
  },
  icon: {
    primary: {
      default: "#4954BA",
      subdued: "#757EC7",
    },
    neutralAlternate: {
      default: "#FFFFFF",
      subdued: "#C7C6C6",
      disabled: "#777675",
    },
    neutral: {
      default: "#474745",
      subdued: "#5F5E5D",
      disabled: "#777675",
    },
    success: {
      default: "#008051",
      subdued: "#05A269",
    },
    warning: {
      default: "#A14D00",
      subdued: "#C57F16",
    },
    error: {
      default: "#C62B46",
      subdued: "#EA4A5D",
    },
    onAction: {
      default: "#FFFFFF",
      subdued: "#FFFFFFB2",
      alternate: "#474645",
      alternateSubdued: "#777675",
    },
    onInteractive: {
      default: "#FFFFFF",
      disabled: "#FFFFFF99",
      alternate: "#453F3D8C",
    },
  },
  border: {
    primary: {
      default: "#4954BA",
      subdued: "#B3B8E5",
      hovered: "#9EA4E1",
      click: "#8890DF",
      disabled: "#D2D2D2",
    },
    neutralAlternate: {
      default: "#5F5E5D",
      subdued: "#474745",
      hovered: "#31312F",
      click: "#31312F",
      disabled: "#777675",
    },
    neutral: {
      default: "#91918F",
      subdued: "#D1D0D0",
      hovered: "#C0C0C0",
      click: "#B8B8B8",
      disabled: "#D2D2D2",
    },
    success: {
      default: "#008051",
      subdued: "#8DFCA6",
      hovered: "#8FF5A0",
      click: "#72ED85",
      disabled: "#D2D2D2",
    },
    warning: {
      default: "#A14D00",
      subdued: "#F9E6A3",
      hovered: "#F5DD89",
      click: "#EEC764",
      disabled: "#D2D2D2",
    },
    error: {
      default: "#C62B46",
      subdued: "#FFC0C7",
      hovered: "#FF99A4",
      click: "#FF6878",
      disabled: "#D2D2D2",
    },
  },
  surface: {
    primary: {
      default: "#D9DBF2",
      hovered: "#8C94F71A",
      click: "#8C94F71F",
      disabled: "#D2D2D2",
    },
    neutralAlternate: {
      default: "#1F2227",
      subdued: "#474645",
      highlight: "#5E5E5C",
      hovered: "#FFFFFF0D",
      click: "#FFFFFF1A",
      disabled: "#454341",
    },
    neutral: {
      default: "#FFFFFF",
      subdued: "#F8F9FB",
      highlight: "#DEDEDD",
      hovered: "#3D2B1E0D",
      click: "#3D2B1E1A",
      disabled: "#D2D2D2",
    },
    success: {
      default: "#D6FBE5",
      hovered: "#00D7561A",
      click: "#00D75626",
      disabled: "#D2D2D2",
    },
    warning: {
      default: "#FBF0C8",
      hovered: "#F9C2001A",
      click: "#F9C20026",
      disabled: "#D2D2D2",
    },
    error: {
      default: "#FEDCDE",
      hovered: "#F61E001A",
      click: "#F61E0026",
      disabled: "#D2D2D2",
    },
  },
  action: {
    primary: {
      default: "#4954BA",
      hovered: "#3944A9",
      click: "#2E33A4",
      disabled: "#777675",
    },
    neutralAlternate: {
      default: "#FFFFFF",
      hovered: "#F1F1F0",
      click: "#E8E8E7",
      disabled: "#777675",
    },
    neutral: {
      default: "#30302F",
      hovered: "#3C3C39",
      click: "#474743",
      disabled: "#777675",
    },
    success: {
      default: "#008051",
      hovered: "#00774B",
      click: "#00643F",
      disabled: "#777675",
    },
    warning: {
      default: "#A14D00",
      hovered: "#904500",
      click: "#803D00",
      disabled: "#777675",
    },
    error: {
      default: "#C62B46",
      hovered: "#AF213A",
      click: "#991930",
      disabled: "#777675",
    },
  },
  //   interactive: {
  //     on: {
  //       default: "#006EDC",
  //       hovered: "#0062C5",
  //       click: "#0051A1",
  //       disabled: "#006EDC99",
  //     },
  //     off: {
  //       default: "#444C56",
  //       hovered: "#5F6976",
  //       click: "#7B8593",
  //       disabled: "#828F96",
  //     },
  //   },
  background: {
    default: "#FBF8F7",
    alternate: "#FFFFFF",
  },
  focus: {
    default: "#EC7100",
  },
  elevationE1: {
    background: "",
    shadow: "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.13)",
  },
  elevationE2: {
    background: "",
    shadow: "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  elevationE3: {
    background: "",
    shadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.13)",
  },
  elevationE4: {
    background: "",
    shadow: "0px 1px 3px rgba(0, 0, 0, 0.11), 0px 6px 14px rgba(0, 0, 0, 0.13)",
  },
  elevationE5: {
    background: "",
    shadow:
      "0px 2px 7px rgba(0, 0, 0, 0.18), 0px 12px 28px rgba(0, 0, 0, 0.22)",
  },
  elevationE6: {
    background: "",
    shadow:
      "0px 5px 15px rgba(0, 0, 0, 0.18), 0px 26px 58px rgba(0, 0, 0, 0.22)",
  },
  borderRadius: "6px",
};

export const defaultDarkTheme: VuelandoTheme = {
  //    text: {
  //      primary: {
  //        default: "#6FB3F6",
  //      },
  //      neutralAlternate: {
  //        default: "#161E26",
  //        subdued: "#636E7D",
  //        placeholder: "#979FA8",
  //        disabled: "#828F96",
  //      },
  //      neutral: {
  //        default: "#FFFFFF",
  //        subdued: "#B2BFC8",
  //        placeholder: "#94A1AB",
  //        disabled: "#7B8992",
  //      },
  //      success: {
  //        default: "#6EDC9A",
  //      },
  //      warning: {
  //        default: "#E1AC5E",
  //      },
  //      error: {
  //        default: "#E87388",
  //      },
  //      onAction: {
  //        default: "#000000",
  //        alternate: "#00000099",
  //      },
  //      onInteractive: {
  //        default: "#FFFFFF",
  //        disabled: "#000000",
  //      },
  //      onSurface: {
  //        default: "#FFFFFF",
  //        disabled: "#7B8992",
  //      },
  //    },
  //    icon: {
  //      primary: {
  //        default: "#6FB3F6",
  //        subdued: "#5C91C5",
  //      },
  //      neutralAlternate: {
  //        default: "#444C56",
  //        subdued: "#8D99A8",
  //        disabled: "#828F96",
  //      },
  //      neutral: {
  //        default: "#E0E1E3",
  //        subdued: "#C9CCD1",
  //        disabled: "#7B8992",
  //      },
  //      success: {
  //        default: "#6EDC9A",
  //        subdued: "#4BB475",
  //      },
  //      warning: {
  //        default: "#E1AC5E",
  //        subdued: "#C1853E",
  //      },
  //      error: {
  //        default: "#E87388",
  //        subdued: "#C9586C",
  //      },
  //      onAction: {
  //        default: "#000000",
  //        subdued: "#19222DB2",
  //        alternate: "#ABB4BE",
  //        alternateSubdued: "#D4D8DE",
  //      },
  //      onInteractive: {
  //        default: "#000000",
  //        disabled: "#19263699",
  //        alternate: "#243B558C",
  //      },
  //    },
  //    border: {
  //      primary: {
  //        default: "#6FB3F6",
  //        subdued: "#32567C",
  //        hovered: "#396797",
  //        click: "#3E76B0",
  //        disabled: "#39484F",
  //      },
  //      neutralAlternate: {
  //        default: "#A6AEB8",
  //        subdued: "#D3DBE5",
  //        hovered: "#C1CAD7",
  //        click: "#ACB7C6",
  //        disabled: "#D1D6D8",
  //      },
  //      neutral: {
  //        default: "#5F6875",
  //        subdued: "#3C434C",
  //        hovered: "#747F8F",
  //        click: "#96A0AF",
  //        disabled: "#39484F",
  //      },
  //      success: {
  //        default: "#6EDC9A",
  //        subdued: "#286B43",
  //        hovered: "#2D8A53",
  //        click: "#309E5D",
  //        disabled: "#39484F",
  //      },
  //      warning: {
  //        default: "#E1AC5E",
  //        subdued: "#6C4827",
  //        hovered: "#89582C",
  //        click: "#A76930",
  //        disabled: "#39484F",
  //      },
  //      error: {
  //        default: "#E87388",
  //        subdued: "#7E1931",
  //        hovered: "#961836",
  //        click: "#B5173D",
  //        disabled: "#39484F",
  //      },
  //    },
  //    surface: {
  //      primary: {
  //        default: "#203B57",
  //        hovered: "#B7D8FB0D",
  //        click: "#B7D8FB1A",
  //        disabled: "#343D45",
  //      },
  //      neutralAlternate: {
  //        default: "#FFFFFF",
  //        subdued: "#F9FAFC",
  //        highlight: "#CFD5DD",
  //        hovered: "#D1D6D8",
  //        click: "#0925490D",
  //        disabled: "#0925491A",
  //      },
  //      neutral: {
  //        default: "#171E24",
  //        subdued: "#14171B",
  //        highlight: "#384654",
  //        hovered: "#343D45",
  //        click: "#FFFFFF0D",
  //        disabled: "#FFFFFF1A",
  //      },
  //      success: {
  //        default: "#215235",
  //        hovered: "#B7FBE20D",
  //        click: "#B7FBE21A",
  //        disabled: "#343D45",
  //      },
  //      warning: {
  //        default: "#523921",
  //        hovered: "#FBCE8C0D",
  //        click: "#FBCE8C1A",
  //        disabled: "#343D45",
  //      },
  //      error: {
  //        default: "#5D162F",
  //        hovered: "#FDA2B20D",
  //        click: "#FDA2B21A",
  //        disabled: "#343D45",
  //      },
  //    },
  //    action: {
  //      primary: {
  //        default: "#6FB3F6",
  //        hovered: "#94C6F8",
  //        click: "#A4CFFA",
  //        disabled: "#343D45",
  //      },
  //      neutralAlternate: {
  //        default: "#263035",
  //        hovered: "#2F3A3F",
  //        click: "#424F55",
  //        disabled: "#828F96",
  //      },
  //      neutral: {
  //        default: "#E8EBED",
  //        hovered: "#D9E0E5",
  //        click: "#BECBD3",
  //        disabled: "#A5B2B9",
  //      },
  //      success: {
  //        default: "#6EDC9A",
  //        hovered: "#87E4AC",
  //        click: "#A5ECC2",
  //        disabled: "#343D45",
  //      },
  //      warning: {
  //        default: "#E1AC5E",
  //        hovered: "#E8BC7B",
  //        click: "#EECC99",
  //        disabled: "#343D45",
  //      },
  //      error: {
  //        default: "#E87388",
  //        hovered: "#EF8E9F",
  //        click: "#F29DAD",
  //        disabled: "#343D45",
  //      },
  //    },
  //    interactive: {
  //      on: {
  //        default: "#6FB3F6",
  //        hovered: "#94C6F8",
  //        click: "#A4CFFA",
  //        disabled: "#143E6399",
  //      },
  //      off: {
  //        default: "#E8EBED",
  //        hovered: "#D9E0E5",
  //        click: "#BECBD3",
  //        disabled: "#A5B2B9",
  //      },
  //    },
  //    background: {
  //      default: "#07070B",
  //      alternate: "#1B1C27",
  //    },
  //    focus: {
  //      default: "#D08328",
  //    },
  //    elevationE1: {
  //      background: "#CEF4FF05",
  //      shadow: "#0F1D39",
  //    },
  //    elevationE2: {
  //      background: "#CEF4FF0A",
  //      shadow: "#0F1D39",
  //    },
  //    elevationE3: {
  //      background: "#D8F6FF0F",
  //      shadow: "#0F1D39",
  //    },
  //    elevationE4: {
  //      background: "#D8F6FF14",
  //      shadow: "#0F1D39",
  //    },
  //    elevationE5: {
  //      background: "#D3F5FF1A",
  //      shadow: "#0F1D39",
  //    },
  //    elevationE6: {
  //      background: "#D8F6FF1F",
  //      shadow: "#0F1D39",
  //    },
  borderRadius: "6px",
};
