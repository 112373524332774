import { BreadCrumb } from "@/models/utils/breadcrumb.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "pagetitle" })
class PageTitleModule extends VuexModule {
  private pageBreadcrumb: BreadCrumb[] = null;

  get breadcrumb(): BreadCrumb[] {
    return this.pageBreadcrumb;
  }

  @Mutation
  public mutateBreadCrumb(breadcrumb: BreadCrumb[]): void {
    this.pageBreadcrumb = breadcrumb;
  }

  @Action
  public setBreadCrumb(breadcrumb: BreadCrumb[]): void {
    this.context.commit("mutateBreadCrumb", breadcrumb);
  }

  @Action
  public resetBreadCrumb(): void {
    this.context.commit("mutateBreadCrumb", null);
  }
}

export default PageTitleModule;
