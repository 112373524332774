import { RouteConfig } from "vue-router";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { getModule } from "vuex-module-decorators";

const Layout = () =>
  import(/* webpackChunkName: "layout" */ "../../views/Layout/Layout.vue");
const NotFound = () =>
  import(/* webpackChunkName: "layout" */ "../../views/ErrorPage/NotFound.vue");
const PublicNotFound = () =>
  import(
    /* webpackChunkName: "layout" */ "../../views/ErrorPage/PublicNotFound.vue"
  );

const authModule = getModule(AuthModule, store);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/404",
    component: PublicNotFound,
    meta: { public: true },
    children: [
      {
        path: "/404",
        name: "PublicNotFound",
        beforeEnter: function (to, from, next) {
          authModule.token ? next({ name: "NotFound" }) : next();
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
    component: Layout,
    meta: { public: false },
    children: [
      {
        path: "/404",
        name: "NotFound",
        component: NotFound,
      },
    ],
  },
];

const errorRoutes = routes.map((route) => route);
export default errorRoutes;
