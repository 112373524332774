
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "./store";
import OverlayModule from "@/store/modules/overlay.module";
import { Snackbar, SnackbarModule } from "@zelando/vuelando";
const overlayModule = getModule(OverlayModule, store);
const snackBarModule = getModule(SnackbarModule, store);

@Component
export default class App extends Vue {
  get overlay(): boolean {
    return overlayModule.status;
  }

  get snackbar(): Snackbar {
    return snackBarModule.current;
  }
}
