import axios, { AxiosError } from "axios";
import router from "@/router";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { initAxiosInterceptors, SnackbarModule } from "@zelando/vuelando";
const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);
// import AbortRequestsModule from "../store/modules/abort-requests.module";
// const abortRequestsModule = getModule(AbortRequestsModule, store);

initAxiosInterceptors({
  successCallback: () => {
    overlayModule.hideOverlay();
  },
  errorCallback: (error: AxiosError) => {
    if (
      !axios.isCancel(error) &&
      error.config.headers["_ignoreInterceptor"] !== "true"
    ) {
      overlayModule.reset();
      if (error.response && error.response.status) {
        switch (error.response.status) {
          /* Not Found */
          case 404:
            snackbarModule.showSnackbar({
              message: "Errore #404: elemento non trovato!",
              type: "error",
              timer: 5000,
            });
            break;
          /* Forbidden */
          case 403:
            snackbarModule.showSnackbar({
              message: "Errore #403: permesso negato!",
              type: "error",
              timer: 5000,
            });
            break;
          /* Unauthorized */
          case 401:
            if (router.currentRoute.path !== "/login") {
              router.push({ name: "Logout" });
              snackbarModule.showSnackbar({
                message: "Errore #401: accesso negato!",
                type: "error",
                timer: 5000,
              });
            }
            break;
          /* Bad Request */
          case 400:
            if (router.currentRoute.path !== "/login") {
              snackbarModule.showSnackbar({
                message: "Errore #400: richiesta non valida!",
                type: "error",
                timer: 5000,
              });
            }
            break;
          case 409:
            snackbarModule.showSnackbar({
              message: "Errore #409: richiesta non valida!",
              type: "error",
              timer: 5000,
            });
            break;
          default:
            snackbarModule.showSnackbar({
              message:
                "Si è verificato un errore imprevisto, riprova più tardi.",
              type: "error",
              timer: 5000,
            });
        }
      } else {
        snackbarModule.showSnackbar({
          message: "Si è verificato un errore imprevisto, riprova più tardi.",
          type: "error",
          timer: 5000,
        });
      }
    }
  },
  useCookies: true,
});
