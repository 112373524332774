import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "language" })
class LanguageModule extends VuexModule {
  private language = "it";

  get current(): string {
    return this.language;
  }

  @Mutation
  public mutateLanguage(language: string): void {
    this.language = language;
  }

  @Action
  public setLanguage(language: string): void {
    this.context.commit("mutateLanguage", language);
  }
}

export default LanguageModule;
