import { RouteConfig } from "vue-router";
import { authenticate } from "@/services/api/authenticate.service";

const Layout = () =>
  import(/* webpackChunkName: "layout" */ "../../views/Layout/Layout.vue");
const Home = () =>
  import(/* webpackChunkName: "home" */ "../../views/Home/Home.vue");
const Account = () =>
  import(/* webpackChunkName: "account" */ "../../views/Account/Account.vue");
const ConfirmationPage = () =>
  import(
    /* webpackChunkName: "NewOrderingSubject" */ "../../views/Account/Components/PaymentsComponents/ConfirmationPage.vue"
  );

const ResetPasswordByLink = () =>
  import(
    /* webpackChunkName: "account" */ "../../views/ResetPassword/ResetPasswordByLink.vue"
  );

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        beforeEnter: async function (to, from, next) {
          const result = await checkAuthorizations();
          result ? next() : next({ path: "/logout" });
        },
      },
      {
        path: "/account/:context?/:payments?",
        name: "account",
        component: Account,
        props: true,
        beforeEnter: async function (to, from, next) {
          const result = await checkAuthorizations();
          result ? next() : next({ path: "/logout" });
        },
      },
      // {
      //   path: "/payments",
      //   name: "payments",
      //   component: Payments,
      // },
      {
        path: "/confirmationpage",
        name: "confirmationpage",
        props: true,
        component: ConfirmationPage,
        beforeEnter: async function (to, from, next) {
          const result = await checkAuthorizations();
          result ? next() : next({ path: "/logout" });
        },
      },
    ],
  },
  {
    path: "/resetpasswordbylink",
    name: "resetPasswordByLink",
    props: true,
    component: ResetPasswordByLink,
    beforeEnter: async function (to, from, next) {
      const result = await checkAuthorizations();
      result ? next() : next({ path: "/logout" });
    },
  },
];

async function checkAuthorizations(): Promise<boolean> {
  //   await loadLanguageAsync(languageModule.current);
  const res = (await authenticate.CheckLogin()).data;
  return res;
}

const privateRoutes = routes.map((route) => route);
export default privateRoutes;
