import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "overlay" })
class OverlayModule extends VuexModule {
  static showOverlay(): void {
    throw new Error("Method not implemented.");
  }
  private overlayStatus = 0;

  get status(): boolean {
    return this.overlayStatus > 0;
  }

  @Mutation
  private setOverlay(state: boolean): void {
    if (state) {
      this.overlayStatus++;
    } else {
      if (this.overlayStatus > 0) {
        this.overlayStatus--;
      }
    }
  }

  @Mutation
  private resetOverlay(): void {
    this.overlayStatus = 0;
  }

  @Action
  public showOverlay(): void {
    this.context.commit("setOverlay", true);
  }

  @Action
  public hideOverlay(): void {
    this.context.commit("setOverlay", false);
  }

  @Action
  public reset(): void {
    this.context.commit("resetOverlay");
  }
}

export default OverlayModule;
