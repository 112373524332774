import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "theme" })
class ThemeModule extends VuexModule {
  private darkMode = false;

  get dark(): boolean {
    return this.darkMode;
  }

  @Mutation
  public mutateTheme(darkMode: boolean): void {
    this.darkMode = darkMode;
  }
  @Action
  public setDarkMode(darkMode: boolean): void {
    this.context.commit("mutateTheme", darkMode);
  }
}

export default ThemeModule;
