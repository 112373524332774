import { RouteConfig } from "vue-router";
const LayoutNoToken = () => import("../../views/Layout/LayoutNoToken.vue");
const VerifyEmailByLink = () =>
  import("../../views/SignUp/VerifyEmailByLink.vue");

const routes: Array<RouteConfig> = [
  {
    path: "/public",
    redirect: "public/404",
    component: LayoutNoToken,
    children: [
      {
        path: "/public/verifyEmailByLink",
        name: "verifyEmailByLink",
        component: VerifyEmailByLink,
      },
    ],
  },
];

const publicRoutes = routes.map((route) => route);
export default publicRoutes;
